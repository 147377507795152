<template>
  <div class="container">
    <div v-show="error" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="clearError"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> {{errorMessage || 'Something went wrong; couldn\'t save your changes.'}}
    </div>
    <dimmer :active="loading" class="row-block">
      <div class="page-header justify-content-center">
        <div class="card mb-0">
          <div class="card-header d-flex justify-content-between align-items-center sticky-header">
            <div class="d-flex align-items-center gap-10">
              <router-link
                :to="`/roles-permissions`"
                class="btn btn-secondary"
                data-test="button-back">
                <i class="fe fe-chevron-left"></i> Back
              </router-link>
              <h3 class="card-title text-capitalize">
                {{role?role.name:'NA'}}
              </h3>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              data-test="button-create-role"
              @click.self.prevent="updateRole">
              Save
            </button>
          </div>
          <div class="card-body">
            <accordion
              v-for="(value, name, index) in groupedPermission"
              :key="index"
              :option="{
                collapse: role.permissions.length > 0,
                disabled: false,
                display: true,
              }
              "
              hide-footer>
              <template v-slot:header>
                <div class="card-title text-uppercase">{{name}} ({{thisServicePermissionsCount(name)}}/{{value.length}})</div>
              </template>
              <div class="card-body">
                <ul class="row p-0">
                  <li
                    v-for="val in value"
                    :key="val.id"
                    class="d-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <label class="custom-control custom-checkbox ">
                      <input
                        type="checkbox"
                        :checked="checkThisPermission(val)"
                        class="custom-control-input"
                        @change="onPermissionChange($event, val)"
                      >
                      <span class="custom-control-label permission-names">{{val.name}}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </accordion>

            <accordion
              v-if="role.users"
              :option="{
                collapse: true,
                disabled: false,
                display: true,
              }
              "
              hide-footer>
              <template v-slot:header>
                <div class="card-title text-uppercase">Users with this role ({{role.users.length}})</div>
              </template>
              <div class="card-body">
                <span v-if="role.users.length === 0">
                  No users are assigned to this role.
                </span>
                <ul class="row p-0">
                  <li
                    v-for="usr in role.users"
                    :key="usr.id"
                    class="d-block col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <router-link
                      if
                      :to="`/users/${usr.customer_id}`"
                    >
                      {{usr.firstName}} {{usr.lastName}}
                    </router-link>
                  </li>
                </ul>
              </div>
            </accordion>
          </div>
        </div>
      </div>
    </dimmer>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion';
import {permissions, roles} from '@/services';
import {groupBy} from 'lodash';

export default {
  name: 'EditRolesAndPermissions',
  components: {
    Accordion,
  },
  data() {
    return {
      loading: false,
      role: {},
      permissionList: [],
      groupedPermission: {},
      openConfirmModal: false,
      errorMessage: '',
      error: false,

    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        // get the role and permissions
        const getRole = roles.getById(id, {
          params: {
            with: 'permissions,users.customer',
          },
        });
        const getPermissions = permissions.get({
          params: {
            pagination: 0,
          },
        });
        // use promise.all to get both responses at once
        const response = await Promise.all([
          getRole,
          getPermissions,
        ]);
        this.role = response[0].item;
        this.permissionList = response[1].items;
        // group permissions by service
        this.groupedPermission = groupBy(this.permissionList, 'service');
      }
      catch (error) {
        console.error(error);
        this.error = true;
        this.errorMessage = error.message;
      }
      finally {
        this.loading = false;
      }
    },
    thisServicePermissionsCount(service) {
      // get the count of permissions for this service
      return this.role.permissions.filter(val => val.service === service).length;
    },
    checkThisPermission(permission) {
      // check if this permission is added to role
      return this.role.permissions.some(item => item.name === permission.name);
    },
    onPermissionChange(e, item) {
      // add or remove permission from role permissions
      if (e.target.checked) {
        this.role.permissions.push(item);
      }
      else {
        this.role.permissions = this.role.permissions.filter(val => val.name !== item.name);
      }
    },
    async updateRole() {
      try {
        this.clearError();
        const confirm = window.confirm('Are you sure you want to save this role?');
        if (confirm) {
          this.loading = true;
          await roles.saveOrUpdate({
            id: this.role.id,
            permissions: this.role.permissions.map(val => val.id),
          });
          this.$toasted.success(`Role "${this.role.name}" is updated successfully!`);
          this.$router.push('/roles-permissions');
        }
      }
      catch (error) {
        console.error(error);
        this.error = true;
        this.errorMessage = `Something went wrong, ${Object.values(error.response?.data?.errors || []).join(',')}`;
        this.$toasted.error(this.errorMessage);
      }
      finally {
        this.loading = false;
      }
    },
    clearError() {
      this.error = false;
      this.errorMessage = '';
    },
  },
};
</script>

<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.permission-names {
  word-break: break-all;
}
</style>
