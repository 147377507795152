var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"alert alert-icon alert-danger alert-dismissible"},[_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":_vm.clearError}}),_c('i',{staticClass:"fe fe-alert-triangle mr-2",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.errorMessage || 'Something went wrong; couldn\'t save your changes.')+" ")]),_c('dimmer',{staticClass:"row-block",attrs:{"active":_vm.loading}},[_c('div',{staticClass:"page-header justify-content-center"},[_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-header d-flex justify-content-between align-items-center sticky-header"},[_c('div',{staticClass:"d-flex align-items-center gap-10"},[_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":"/roles-permissions","data-test":"button-back"}},[_c('i',{staticClass:"fe fe-chevron-left"}),_vm._v(" Back ")]),_c('h3',{staticClass:"card-title text-capitalize"},[_vm._v(" "+_vm._s(_vm.role?_vm.role.name:'NA')+" ")])],1),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-test":"button-create-role"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.preventDefault();return _vm.updateRole.apply(null, arguments)}}},[_vm._v(" Save ")])]),_c('div',{staticClass:"card-body"},[_vm._l((_vm.groupedPermission),function(value,name,index){return _c('accordion',{key:index,attrs:{"option":{
              collapse: _vm.role.permissions.length > 0,
              disabled: false,
              display: true,
            },"hide-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title text-uppercase"},[_vm._v(_vm._s(name)+" ("+_vm._s(_vm.thisServicePermissionsCount(name))+"/"+_vm._s(value.length)+")")])]},proxy:true}],null,true)},[_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"row p-0"},_vm._l((value),function(val){return _c('li',{key:val.id,staticClass:"d-block col-xl-4 col-lg-4 col-md-6 col-sm-12"},[_c('label',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.checkThisPermission(val)},on:{"change":function($event){return _vm.onPermissionChange($event, val)}}}),_c('span',{staticClass:"custom-control-label permission-names"},[_vm._v(_vm._s(val.name))])])])}),0)])])}),(_vm.role.users)?_c('accordion',{attrs:{"option":{
              collapse: true,
              disabled: false,
              display: true,
            },"hide-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title text-uppercase"},[_vm._v("Users with this role ("+_vm._s(_vm.role.users.length)+")")])]},proxy:true}],null,false,1697513219)},[_c('div',{staticClass:"card-body"},[(_vm.role.users.length === 0)?_c('span',[_vm._v(" No users are assigned to this role. ")]):_vm._e(),_c('ul',{staticClass:"row p-0"},_vm._l((_vm.role.users),function(usr){return _c('li',{key:usr.id,staticClass:"d-block col-xl-3 col-lg-4 col-md-6 col-sm-12"},[_c('router-link',{attrs:{"if":"","to":("/users/" + (usr.customer_id))}},[_vm._v(" "+_vm._s(usr.firstName)+" "+_vm._s(usr.lastName)+" ")])],1)}),0)])]):_vm._e()],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }